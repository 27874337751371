import type { ReactNode, RefObject } from 'react';
import SVGIcon from '../ui/Icons/SVGIcon';

type TProps = {
  children: ReactNode;
  open?: boolean;
  title?: string;
  extraClass?: string;
  mobileFullWidth?: boolean;
  positionTop?: boolean;
  desktopWide?: boolean;
  createPortalModal?: boolean;
  modalRef?: RefObject<HTMLDivElement>;
  id?: string;
  variantBack?: boolean;
  extraClasses?: string;
  animate?: boolean;
  mobileFullScreen?: boolean;
};

const GenericModal = ({
  children,
  title,
  open = true,
  modalRef,
  id = 'exampleModal',
  variantBack = false,
  extraClasses = '',
  animate = true,
  mobileFullScreen = true,
}: TProps) => {
  if (!open) return <></>;

  return (
    <div
      className={`modal ${animate && 'fade'} ${extraClasses}`}
      id={id}
      tabIndex={-1}
      aria-labelledby={`${id}Label`}
      aria-hidden="true"
      ref={modalRef}
    >
      <div
        className={`modal-dialog modal-dialog-centered modal-dialog-scrollable ${
          mobileFullScreen && 'modal-fullscreen-md-down'
        }`}
      >
        <div className="modal-content">
          <div className={`modal-header ${variantBack ? '-variant-back' : ''}`}>
            {variantBack ? (
              <>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="fo-icon fo-icon-arrow-left"></span>
                  <span className="fo-display-5 -medium">{title}</span>
                </button>
              </>
            ) : (
              <>
                <span className="fo-display-5 -medium">{title}</span>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="p-0"
                >
                  <SVGIcon
                    iconType={'cross'}
                    className="fo-icon-md ms-auto color-grey-600"
                  />
                </button>
              </>
            )}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default GenericModal;
